@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;

}

.container {
  max-width: 1170px;
  margin: auto;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  overflox-x: hidden;
  background-color: var(--bg-black-50);
  /* background-color: ; */
}

::before,
::after {
  box-sizing: border-box;
}

._row {
  display: flex;
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}


.h-100 {
  min-height: 100vh;
}

.h-80 {
  min-height: 80vh;
}

.outer-shadow {
  box-shadow: var(--outer-shadow);
}

.inner-shadow {
  box-shadow: var(--inner-shadow);
}

.hover-in-shadow {
  position: relative;
  z-index: 1;
}

.hover-in-shadow:hover {
  box-shadow: var(--outer-shadow-0);
}

.hover-in-shadow:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.hover-in-shadow:hover:after {
  box-shadow: var(--inner-shadow);
}

.card-1 {
  /* width: 80%; */
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 50px 20px;
  z-index: 99;

}

.btn {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: capitalize;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-1 {
  background-color: white;
  color: var(--black-253137);
  font-weight: 500;

}

.btn-1:hover {
  background-color: transparent;
  border-color: white;
  color: white;
}

.form-control {
  height: 45px;
  width: 100%;
  display: block;
  margin-bottom: 30px;
  padding: 0 15px;
  border-radius: 30px;
  background-color: var(--blue-light);
  border: 1px solid transparent;
  font-size: 14px;
  font-family: 'Poppins';
  color: var(--white-900);
  transition: all 0.5s ease;

}

.form-control::-webkit-input-placeholder {
  color: var(--white-800);
}

.form-control:-ms-input-placeholder {
  color: var(--white-800);
}

.form-control::placeholder {
  color: var(--white-800);
}

.form-control:focus {
  border-color: var(--white-900);
}

.message
{
  text-align:center;
  margin-top:10px;
  color:var(--white-900);
}

/* ------------------------
Effects
--------------------------- */


.effect-wrap .effect {
  position: absolute;
  z-index: -1;
}

.effect-1 {
  width: 30px;
  height: 30px;
  border: 4px solid #8a49ff;
  right: 5%;
  bottom: 15%;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.effect-2 {
  left: 3%;
  top: 50%;
  width: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-animation: topBounce 3s ease-in-out infinite;
          animation: topBounce 3s ease-in-out infinite
}

.effect-2 div {
  background-color: #ff9c07;
  height: 3px;
  width: 3px;
  margin: 0 3px 8px;
}

.effect-3 {
  height: 50px;
  width: 50px;
  border: 14px solid var(--skin-color);
  border-radius: 50%;
  left: 50%;
  top: 15%;
  -webkit-animation: leftbounce 3s ease-in-out infinite;
          animation: leftbounce 3s ease-in-out infinite;
  z-index: 25;
}

.effect-4 {
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  left: 30%;
  top: 30%;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

.effect-4:before {
  content: '';
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  opacity: 0.5;
  position: absolute;
  left: -35px;
  top: -25px;

}

.effect-5 {
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: space-between;
  border-radius: 50%;
  overflow: hidden;
  top: 18%;
  right: 6%;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

.effect-5 div {
  width: 1px;
  background-color: #4dd0e1;
}


.effect-6 {
  width: 120px;
  height: 120px;
  background-color: #001D5D;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  right: 15%;



}

.ocean {
  height: 80px;
  /* change the height of the waves here */
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;

  -webkit-animation: wave 10s -3s linear infinite;

          animation: wave 10s -3s linear infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  -webkit-animation: wave 20s -1s linear infinite;
          animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);

  }

  100% {
    -webkit-transform: rotate(306deg);
            transform: rotate(306deg)
  }

}


@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);

  }

  100% {
    -webkit-transform: rotate(306deg);
            transform: rotate(306deg)
  }

}

@-webkit-keyframes topBounce {

  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px)
  }
}

@keyframes topBounce {

  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px)
  }
}

@-webkit-keyframes leftbounce {

  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }

  60% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px)
  }
}

@keyframes leftbounce {

  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }

  60% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px)
  }
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }

}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }

}



/* ---------------------
Navbar
---------------------- */
ul {
  list-style: none;
}

a {
  text-decoration: none;

}

li {
  display: block;
}



img {
  vertical-align: middle;
  max-width: 100%;
}

.row-1 {
  display: flex;
  flex-wrap: wrap;
}

/* -------------
header 
------------------*/


.header {
  background-color: var(--bg-black-50);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: var(--outer-shadow);
}

.header .logo {
  /* background-color: blue; */
  padding: 0 15px 5px 0;
}

.header .logo a {
  font-size: 24px;
  color: var(--text-blue-700);
  text-transform: capitalize;
  font-weight: 600;

}

.header #nav-check {
  position: absolute;
  pointer-event: none;
  opacity: 0;
}

.header #nav-check:focus~.nav-toggler {
  box-shadow: 0 0 10px var(--text-blue-700)
}

.header .nav-toggler {
  height: 34px;
  width: 44px;
  border: 1px solid var(--text-blue-700);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
}

.header .nav-toggler span {
  display: block;
  width: 20px;
  height: 2px;
  background-color: var(--text-blue-700);
  position: relative;
}

.header #nav-check:checked~.nav-toggler span {
  background-color: transparent;
}

.header .nav-toggler span::before,
.header .nav-toggler span::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--text-blue-700)
}

.header .nav-toggler span::before {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px)
}

.header #nav-check:checked~.nav-toggler span::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg)
}

.header .nav-toggler span::after {
  -webkit-transform: translateY(6px);
          transform: translateY(6px)
}

.header #nav-check:checked~.nav-toggler span::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg)
}

.header .nav {

  padding: 0 15px 10px;
  /* background-color:yellow; */

}


.header .nav ul li {
  display: inline-block;
  margin-left: 40px;
  text-align: center;
  vertical-align: middle;


}


.header .nav ul li a:hover {
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid transparent;
  color: white;
  background-color: var(--text-blue-700);
  font-weight: 500;
  transition: all 0.3 ease;
  padding: 2px 8px;

}

.header .nav ul li a:active {
  background-color: pink;
}

.header .nav ul li a {
  font-size: 16px;
  display: block;
  padding: 10px 0;
  font-weight: 400;
  color: var(--text-blue-700);
  transition: all 0.5s ease;

}

.header .nav ul li ul {
  visibility: hidden;
  transition: all 0.5s ease;
  display: none;
}

.header .nav ul li:hover>ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

.header .nav ul li ul li {
  /* background-color: grey; */
  display: block;
  text-align: center;
}




/* ---------------------
Home section
----------------------- */



.home-section {
  /* background-color:var(--text-blue-700); */
  margin-bottom: 10rem;
}

.home-section .h-100 {
  padding: 120px 0;
  /* background-color:black; */
}

.home-section .home-text {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  position: relative;

}

.home-section .home-text .home-text-inner h1 {

  font-size: 35px;
  position: absolute;
  left: 4%;
  font-weight: 900;
  color: var(--blue-light);
  text-transform: uppercase;
  line-height: 1.5;
  z-index: 1;
}
.home-section .home-text .home-text-inner h3
{
  margin-left: 4.5%;
  line-height: 0.5;
  font-size: 15px;
  font-weight: 700;
  color: var(--blue-light);
  text-transform: uppercase;

  z-index: 1;
}

.home-section .home-text .home-text-inner h1:before {
  content: attr(data-heading);
  display: block;
  color: var(--text-blue-700);

}

.home-section .home-text .home-text-inner h1::after {
  content: '';
  position: absolute;
  left: 6%;
  width: 50%;
  height: 2px;
  bottom: 0;
  background-color: var(--text-blue-700);
  -webkit-transform: skewY(-6deg);
          transform: skewY(-6deg);
  z-index: -1;
}

.uderl .home-section {
  /* background-color:yellow; */
}




.home-section .home-img {
  /* background-color: blue; */
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  /* margin-top:-0px; */
}

.home-section .home-img .img-box {
  z-index: 1;

}

/* .home-section .home-img .img-box img {

  width: 100%;
  border: 10px solid transparent;
  border-radius: 5px;

} */

.home-section .about-info {
  flex: 0 0 40%;
  max-width: 40%;
  /* background-color: yellow; */
  padding: 0 15px;
  position: relative;
}

.home-section .card-2 {
  width: 100%;
  position: absolute;
  right: -30px;
  bottom: -30px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 50px 20px;
  z-index: 80;




}


.home-section .about-info .card-info .card-2 p {
  font-size: 16px;
}

.home-section .about-info .card-info .card-2 span {
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
}

/*-----------------------------------
 welcome section
----------------------------------- */

.welcome-section {
  /* background-color: red; */
  padding: 15px;
  margin-bottom: 100px;
  display: flex;


}

/* .welcome-section .welcome-space
{
  flex: 0 0 5%;
} */


.welcome-section .welcome-img {
  /* background-color:blue; */
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 15px;
}

.welcome-section .welcome-img .img-box {
  padding: 15px;
  position: relative;

}

.welcome-section .welcome-img .img-box::before,
.welcome-section .welcome-img .img-box::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border: 2px solid var(--text-blue-700);
  transition: all .25s ease-out;
}

.welcome-section .welcome-img .img-box::before {
  background-color: var(--text-blue-700);
  box-shadow: var(--card-shadow);
  top: -15px;
  left: -15px;
}

.welcome-section .welcome-img .img-box::after {
  bottom: -15px;
  right: -15px;
}

.welcome-section .welcome-img .img-box:hover::after {


  bottom: 15px;
  right: 15px;

}

.welcome-section .welcome-img .img-box:hover::before {
  top: 15px;
  left: 15px;
}

.welcome-section .welcome-img .img-box:hover::after {
  bottom: 15px;
  right: 15px;
}

.welcome-section .welcome-img .img-box img {

  width: 100%;
  /* border: 10px solid transparent; */
  /* border-radius: 5px; */

}




.welcome-section .welcome-info {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.welcome-section .welcome-info h2 {
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--black-253137);
  line-height: 1.2;
  margin-bottom: 20px;
}


.welcome-section .welcome-info h2::before {
  content: attr(data-heading);
  display: block;
  font-size: 15px;
  font-weight: 600;
  text-transform: lowercase;
}



.welcome-section .welcome-info p {
  line-height: 1.2;
  font-size: 16px;
  margin-bottom: 25px;
  text-align: justify;
}

.welcome-section .welcome-info span {
  font-weight: 700;
}





/*---------------------
Service
----------------------- */

.service-section {
  /* background-color:yellow; */
  margin-bottom: 100px;
  padding: 15px;
}

.service-section .service-text,
.service-section .service-slider {
  flex: 0 0 50%;
  max-width: 50%;
}

.service-section .service-text {
  /* background-color: red; */
  display: flex;

}

.service-section .service-text h1 {
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--black-253137);
  line-height: 1;
}

.service-section .service-text h1::before {
  content: attr(data-heading);
  display: block;
  font-size: 16px;
  margin-left: 5px;
  text-transform: lowercase;

}

.service-section .service-slider {
  /* background-color: pink; */
}

.service-section .service-slider .service-card {
  flex: 0 0 50%;
  max-width: 50%;
}

.service-section .service-slider .circle-btn {
  flex: 0 0 20%;
  max-width: 20%;
}

.service-section .service-slider .circle-btn {
  /* background-color:blue; */
  display: flex;
}

.service-section .service-slider .circle-btn .font-awesome {
  background-color: var(--text-blue-700);
  width: 40px;
  height: 40px;
  font-size: 12px;
  text-align: center;
  line-height: 42px;
  border-radius: 30px;
  box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.service-section .service-slider .circle-btn .font-awesome .icon {
  /* background-color: orange; */
  color: white;
  font-size: 15px;

}

.service-section .service-slider .service-card {

  /* margin: 50px 10px; */


}

.service-section .service-slider .service-card .service-item {
  text-align: center;
  padding: 10px;
}

.service-section .service-slider .service-card .service-item img {
  /* background-color:orange; */
  max-width: 200px;
  width: 50%;
  height:50%;
}

.service-section .service-slider .service-card .service-item h2 {
  color: var(--text-blue-700);
  font-weight: 800;
  font-size: 25px;
}

.service-section .service-slider .service-card .service-item p {
  text-color: var(--text-color-black);
  font-weight: 500;

}


/*-----------------------
expertise-section
-------------------------*/

.expertise-section {
  background-color: var(--blue-light);
  padding: 15px;
  margin-bottom: 100px;
}

.expertise-section .expertise-intro {
  display: flex;
  justify-content: center;
  padding: 15px;
  margin-bottom: 50px;
}

.expertise-section .expertise-intro h1 {
  color: var(--white-900);
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1;
  padding-top: 15px;
}

.expertise-section .expertise-intro p {
  content: attr(data-heading);
  display: block;
  font-size: 13px;
  color: var(--white-900);
  margin-left: 5px;

}


.expertise-section .expertise-item {
  /* background-color:red; */
  flex: 0 0 50%;
  padding: 0 15px;
  margin-bottom: 50px;
}

.expertise-section .expertise-item .expertise-item-img {
  flex: 0 0 25%;
}

.expertise-section .expertise-item .expertise-item-img img {
  width: 70%;
  max-width: 100%;
}


.expertise-section .expertise-item .expertise-item-text {
  flex: 0 0 75%;
  padding: 15px;
}

.expertise-section .expertise-item .expertise-item-text h3 {
  color: var(--white-900);
  text-transform: uppercase;
  font-size: 20px;
}

.expertise-section .expertise-item .expertise-item-text p {
  color: var(--white-900);
  font-size: 14px;
}

/*-----------------------
Query Section
----------------------- */

.service-section .query-form {
  flex: 0 0 50%;
  max-width: 50%;
  /* justify-content:center; */
  /* display:flex; */
  /* background-color: red; */
}

.query-form form {
  width: 100%;
  max-width: 350px;
  background-color: var(--text-blue-700);
  padding: 30px;
  border-radius: 4px;
  margin: auto;
  box-shadow: var(--blue-light) 0px 4px 16px, var(--blue-light) 0px 8px 32px;
}

.query-form textarea.form-control {
  height: 120px;
  resize: none;
  padding-top: 15px;
}

.query-form .btn-1 {
  height: 45px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

/*-----------------------
Footer
------------------------ */

footer {
  background-color: var(--blue-light);
  padding: 50px;
}

.footer-item {
  width: 33.33%;
  padding: 0 15px;
}

.footer-item h2 {
  color: var(--white-900);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 30px;
}

.footer-item p {
  color: var(--white-800)
}

.social-links {
  margin: 20px 0;

}

.social-links .link {

  display: inline-block;
  border: 1px solid transparent;
  height: 35px;
  width: 35px;
  color: var(--white-900);
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  transition: all 0.5s ease;
  border-radius: 50%;
  margin-left: 10px;

}

.social-links .link:hover {
  border: 1px solid var(--white-900);

}

.footer-quick-link ul li .link {
  display: block;
  color: var(--white-900);
  padding: 8px 0;
  text-transform: capitalize;
  font-size: 16px;

}

.footer-contact p {
  margin: 0 0 15px;
  position: relative;
  padding-left: 30px;
}

.footer-contact p .i {
  position: absolute;
  left: 0;
  top: 5px;
}

.footer-newsletter .btn-1 {
  height: 45px;
  width: 100%;
}

.footer-newsletter .form-control {
  margin-bottom: 15px;
  text-align: center;
  background-color: var(--text-blue-700);
}

/*-------------------------
Contacts
------------------------- */

.contact-page {
  padding: 100px 20px;
  background-color: var(--text-blue-700);
  box-shadow: var(--blue-light) 0px 4px 16px, var(--blue-light) 0px 8px 32px;
  border-radius: 10px;
  margin: 100px 50px;

}

.contact-info {
  flex: 0 0 30%;
  max-width: 30%;
  transition: all 0.3s ease;

}

.contact-query {
  flex: 0 0 70%;
  max-width: 70%;
}

.contact-info h1 {
  text-transform: capitalize;
  color: var(--bg-black-100);
  margin: 0 0 10px 0;
}

.contact-info p {
  margin: 0 0 15px;
  position: relative;
  padding-left: 30px;
  color: var(--white-900);
}

.contact-info p .i {
  position: absolute;
  left: 0;
  top: 5px;
}

.contact-query .msg,
.contact-query .phone {
  width: calc(50%);

}

.contact-query .phone {
  padding-left: 15px;
}

.contact-query textarea.form-control {
  height: 130px;
  resize: none;
  padding-top: 15px;
}

.contact-query .btn-1 {
  height: 45px;

  font-size: 16px;
  font-weight: 600;
}







/*------------------------------------------
  Responsive
------------------------------------------ */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .h-100 {
    min-height: 20vh;
  }

  .home-section {
    /* background-color:pink; */

  }

}

@media (max-width:991px) {

  .header .logo a {
    padding: 0 0 0 20px;
  }

  .header {
    /* background-color:transparent;  */
  }

  .header .nav-toggler {
    display: flex;
    margin-right: 15px;
  }

  .header .nav {
    width: 100%;
    position: absolute;
    background-color: var(--bg-black-100);
    top: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;

  }

  .header #nav-check:checked~.nav {
    visibility: visible;
    opacity: 1;
    z-index: 99;
  }

  .header .nav ul li {
    margin: 0;
    display: block;
    margin-bottom: 5px;
  }

  .header .nav ul li a {
    /* background-color: pink; */
    text-align: left;
  }

  .header .nav ul li a:hover {
    color: var(--text-blue-700);
    border-bottom: none;
    color: var(--white-900);
  }

  .home-section .home-text .home-text-inner h1 {
    font-size: 30px;
  }

  .home-section .about-info {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    position: relative;
  }

  .home-section .card-2 {

    width: 80%;
    position: absolute;
    /* right: 0px; */
    left: 10%;
    bottom: 0px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgb(17 17 26 / 30%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
    padding: 50px 20px;
    z-index: 80;
  }

  .home-section .home-img {
    /* background-color: red; */
    padding: 10px 10px;
    margin-top: 40px;
  }


  /* welcome section */
  .welcome-space {
    display: none;
  }

  .welcome-section .welcome-img {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .welcome-section .welcome-info h2 {
    font-size: 28px;
  }

  .effect-6 {
    right: 3%;
  }

  /* service section */


  .service-section .service-text h1 {
    font-size: 38px;
  }

  /* ---------------------
  Expertise
  ------------------- */

  .expertise-section .expertise-item {}

  /*----------------------
  contact
  ----------------------  */

  .contact-info {
    /* background-color: black; */
  }

}

@media(max-width:767px) {


  .home-section .home-text .home-text-inner h1 {
    font-size: 28px;
  }



  .home-section .card-2 {
    width: 100%;
    position: absolute;

    left: 4%;
    bottom: 0px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgb(17 17 26 / 30%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
    padding: 50px 20px;
    z-index: 80;
  }

  .welcome-section .welcome-img {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0;
  }

  .welcome-section .welcome-info h2 {
    font-size: 26px;
  }

  /* service section */

  .service-section .service-text h1 {
    font-size: 36px;
  }

  /* Contact */
  .contact-info {
    /* background-color: black; */
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease;
  }

  .contact-query {
    flex: 0 0 100%;
    max-width: 100%;

  }

}

@media (max-width:575px) {


  .effect-3 {

    left: 50%;
    top: 8%;

  }

  .home-section .h-100 {
    margin-top: 100px;
    padding: 0px 0;
    /* background-color: black; */
  }

  .home-section .home-text .home-text-inner h1 {
    font-size: 25px;
  }

  .home-section .about-info {

    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
    position: relative;
  }

  .home-section .home-about-content {
    /* background-color:pink; */
    margin: 100px 0;
  }

  .home-section .card-2 {

    width: 100%;
    text-align: center;
    position: relative;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: transparent;
    box-shadow: none;
    padding: 50px 20px;
    z-index: 80;

  }

  .home-section .home-img {
    /* background-color: blue; */
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
    /* margin-top: -0px; */
  }

  .effect-6 {
    display: none;
  }

  .welcome-section .welcome-img {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
  }

  .welcome-section .welcome-info {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px;
    text-align: center;
  }

  /* service section */

  .service-section {
    padding: 0px;
  }

  .service-section .service-text,
  .service-section .service-slider {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .service-section .service-text h1 {
    font-size: 34px;
    text-align: center;
    margin: 10px 0 50px 0;
  }

  .service-section .service-carasual {
    /* background-color: green; */
    justify-content: center;
  }

  .service-section .service-slider .service-card .service-item h2 {
    font-size: 18px;
  }

  .service-section .service-slider .service-card .service-item p {
    font-size: 14px;
  }

  /* Expertise */
  .expertise-section .expertise-intro h1 {
    font-size: 34px;

  }

  .expertise-section .expertise-item {
    flex: 0 0 100%;
    max-width: 100%;

  }

  .service-section .query-form {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .query-form form {
    width: 85%;
  }


  .footer-item {
    width: 100%;
    text-align: center;
  }


  /* contact */
  .contact-page {
    margin: 100px 10px;
  }

  .contact-info {
    /* background-color: black; */
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease;
  }

  .contact-query {
    flex: 0 0 100%;
    max-width: 100%;

  }


  .contact-query .msg,
  .contact-query .phone {
    width: calc(100%);

  }

  .contact-query .phone {
    padding-left: 0px;
  }

}
:root {
    --bg-black-900: #000000;
    --bg-black-100: #dddddd;
    --bg-black-50: #eff0f4;
    --text-black-900: #000000;
    --text-black-700: #555555;
    --text-black-600: #666666;
    --text-black-300: #bbbbbb;
    --text-blue-700:#001D5D;
    --outer-shadow: 3px 3px 3px #d0d0d0, -3px -3px 3px #f8f8f8;
    --outer-shadow-0: 0px 0px 0px #d0d0d0, 0px 0px 0px #f8f8f8;
    --inner-shadow: inset 3px 3px 3px #d0d0d0, inset -3px -3px 3px #f8f8f8;
    --skin-color:#fb839e;
    --white-900:#ffffff;
    --white-800:rgba(255,255,255,0.8);
    --shadow: 0 0 15px rgba(0,0,0.2);
    --color-gray:#5E5C5F;
    --black-121212:#121212;
    --black-181818:#181818;
    --black-404040:#404040;
    --black-282828:#282828;
    --black-B3B3B3:#B3B3B3;
    --black-253137:#253137;
    --blue-light:#2D4579;
    --card-shadow:rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;


      scroll-behavior:smooth;
  }

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */

 .tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
/* Effect */

.about-page {
    padding: 160px 0 0 0;
    position: relative;
    margin-bottom: 100px;
}


/*----------------------
Side Menu
---------------------- */

.about-page .about-side-menu {

    /* background-color: green; */
    flex: 0 0 20%;
    max-width: 20%;
    /* background-color: white;
    box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 10px; */
}

.about-page .about-side-menu .more-in-about {

    border-radius: 5px;

}


.about-page .about-side-menu .more-in-about span {
    color: var(--black-253137);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding: 5px;
    display: block;
    margin: 10px 0 0 15px;
    text-align: right;
    opacity: 0.5;
}

.about-page .about-side-menu ul {
    /* list-style: none; */
    margin: 15px 0 0 0;
}

.about-page .about-side-menu ul li {
    display: block;
    text-align: right;
    cursor: pointer;


}


.about-page .about-side-menu ul li div {

    font-size: 14px;
    color: var(--black-253137);
    font-weight: 400;
    padding: 10px 0px;
    opacity: 0.5;
}

.about-page .about-side-menu ul li div:hover {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-blue-700);
    transition: all 0.5s ease;
    opacity: 1;

}

.about-page .about-side-menu ul li div.active-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-blue-700);
    transition: all 0.5s ease;
    opacity: 1;
}

.about-page .about-side-menu ul li div span {
    padding: 0 15px;
    cursor: pointer;
}


/*------------------------
Drawer right
------------------------ */
.side-menu-drawer {

    margin-right: 20px;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 80px;
    display: none;
}


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20;
    top: 6.5%;
    right: 0;
    background-color: var(--blue-light);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/*  */


.sidenav .more-in-about {

    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    padding-right: 30px;

}


.sidenav .about-side-menu .more-in-about span {
    color: var(--black-253137);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding: 5px;
    display: block;
    margin: 10px 0 0 15px;
    text-align: right;
    opacity: 0.5;
}

.sidenav ul {
    /* list-style: none; */
    margin: 15px 0 0 0;

}

.sidenav ul li {
    display: block;
    text-align: right;
    cursor: pointer;
    padding-right: 30px;


}


.sidenav ul li div {

    font-size: 14px;
    color: var(--white-800);
    font-weight: 400;
    padding: 10px 0px;
    opacity: 0.5;
}

.sidenav ul li div:hover {
    font-size: 15px;
    font-weight: 500;
    color: var(--white-800);
    transition: all 0.5s ease;
    opacity: 1;

}

.sidenav ul li div.active-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--white-900);
    transition: all 0.5s ease;
    opacity: 1;
}

.sidenav ul li div span {
    padding: 0 15px;
    cursor: pointer;
}


@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

/* ----------------------
Certificate
------------------------- */
.about-page .certification-info {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;

}

.about-page .certification-info .certificate-image {

    flex: 0 0 40%;
    max-width: 40%;
    position: relative;

}

.certificate-inner-image .img-rectange {
    /* background-color: var(--text-blue-700); */
    border:2px solid var(--text-blue-700);
    width: 100%;
    opacity: 1;
     position: relative;
}

.certificate-image .certificate-inner-image {
    padding: 15px;
    position: relative;
    
}

.certificate-image .certificate-inner-image::before,
.certificate-image .certificate-inner-image::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border: 2px solid var(--text-blue-700);
  transition: all .25s ease-out;
}

.certificate-image .certificate-inner-image::before {
  background-color: var(--text-blue-700);
  box-shadow:var(--card-shadow);
  top: -10px;
  left: -10px;
}
.certificate-image .certificate-inner-image::after {
  bottom: -10px;
  right: -10px;
}
.certificate-image .certificate-inner-image:hover::after  {

  
    bottom: 10px;
    right: 10px;
  
}
.certificate-image .certificate-inner-image:hover::before {
  top: 10px;
  left: 10px;
}
.certificate-image .certificate-inner-image:hover::after {
  bottom: 10px;
  right: 10px;
}

.certificate-inner-image img {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 4;
    transition:all 0.5s ease;
}
.certificate-inner-image img:hover
{
    top: 0px;
    left: 0px;
}


.about-page .certification-info .certificate-text {

    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 15px;
}

.certificate-heading h1 {
    text-transform: uppercase;
    color: var(--black-253137);
    padding: 0 0 15px 0;
}


.certificate-heading p {
    /* background-color: red; */
    line-height: 1.5;
    font-size: 16px;
}


.certificate-heading h1 span {
    display: block;
    margin-left: 50px;
}



/* ---------------------------
Quality Policy 
----------------------------*/

.about-page .about-quality-policy {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;
    /* background-color: red; */
}

.about-page .about-quality-policy .quality-policy-text {
    position: relative;
}

.about-page .about-quality-policy .inner-text {
    color: var(--black-253137);

}

.about-page .about-quality-policy .inner-text h1 {
    text-transform: uppercase;
    font-size: 45px;
    margin: 0 0 15px 0;
}

.about-page .about-quality-policy .inner-text p {
    text-align: justify;
    line-height: 1.5;
    font-size: 16px;
}

.firstcharacter {
    color: #5f79ff;
    float: left;
    margin: 0 8px 0 4px;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
}

.about-page .about-quality-policy .quality-policy-text .inner-text .inner-img {
    width: 50%;
    float: right;
    /* background-color: pink; */

}

.about-page .about-quality-policy .quality-policy-text .inner-text .inner-img img {
    padding: 0 10px;
}

.about-page .quality-policy-list {
    /* background-color: pink; */
}

.about-page .quality-policy-list ul {
    margin: 10px 0;
}

.about-page .quality-policy-list ul li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1em;
    padding: 5px 0;
    color: var(--black-253137)
}

/*------------------------
Introduction Letter
-------------------------  */

.about-page .intro-letter {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;
    transition: all 0.5s ease;
    /* background-color: red; */
}

.about-page .intro-letter .inner-text {
    color: var(--black-253137);
}

.about-page .intro-letter .inner-text h1 {
    text-transform: uppercase;
    font-size: 40px;
    margin: 0 0 15px 0;
}

.about-page .intro-letter .inner-text img {
    float: right;
    width: 40%;
}

.about-page .intro-letter .inner-text p {
    text-align: justify;
}

.about-page .intro-letter .inner-text p p span {
    margin: 10px 20px 20px 0;
}

.about-page .intro-letter .inner-text p p {
    margin: 10px 20px 20px 0;
}


/*----------------------------
Responsive
---------------------------- */
@media (max-width:991px) {
    .side-menu-drawer {
        display: block;
    }
    
    .about-page
    {
        padding: 50px 0 0 0;
    }
    .about-page .about-side-menu {
        /* background-color: red; */
        display: none;
    }

    .about-page .certification-info {

        flex: 0 0 80%;
        max-width: 80%;
        margin-left: 15%;
        /* margin:0 0 0 50px; */
    }


}

@media(max-width:767px) {

    .about-page .about-quality-policy {

        flex: 0 0 80%;
        max-width: 80%;
        /* margin-left: 15%; */
        /* background-color: red; */
    }

    .about-page .intro-letter {

        flex: 0 0 80%;
        max-width: 80%;
        transition: all 0.5s ease;
        /* background-color: red; */
    }

}

@media (max-width:575px) {

    .about-page {
        padding: 50px 0 0 0;
    }
    
    .about-page .certification-info {
        /* background-color: red; */
    }

    .about-page .certification-info .certificate-image {
        flex: 0 0 80%;
        max-width: 80%;

    }

    .certificate-inner-image .img-rectange {
        width: 80%;
        margin-bottom: 50px;

    }

    .certificate-inner-image img {
        max-width: 100%;
        left: 70px;
        top: -50px;
        z-index: 10;
        transition: all 0.9s ease;
        -webkit-animation: topBounce;
                animation: topBounce;
    }

    .about-page .certification-info .certificate-text {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .certificate-heading {
        /* background-color: pink; */
        line-height: 1.2;
    }

    .certificate-heading h1 {
        text-align: center;
        margin-top: 50px;
    }

    .certificate-heading h1 span {
        /* display: block; */
        margin-left: 0px;
    }

    .about-page .about-quality-policy {

        flex: 0 0 80%;
        max-width: 80%;
        /* margin-left: 15%; */
        /* background-color: red; */
    }

    .about-page .intro-letter {

        flex: 0 0 80%;
        max-width: 80%;
        transition: all 0.5s ease;
        /* background-color: red; */
    }
}
.service-top {

  padding: 120px 0 15px 0;
  position: relative;
  background-color: var(--bg-black-50);
  box-shadow: var(--outer-shadow);

}

.service-page .service-header {
  margin-bottom: 50px;
}


.service-page {
  /* background-color:yellow; */
  margin-bottom: 100px;
  padding: 15px;
}

.service-page .service-text {
  flex: 0 0 50%;
  max-width: 50%;

}

.service-page .service-text {
  /* background-color: red; */
  display: flex;

}

.service-head {}

.service-page .service-text h1 {
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--black-253137);
  line-height: 1;
  text-align: center;
  padding: 20px 0;
}

.service-page .service-text h1::before {
  content: attr(data-heading);
  display: block;
  font-size: 16px;
  margin-left: 5px;
  text-transform: lowercase;

}

.service-page .service-img {

  flex: 0 0 50%;
  max-width: 50%;
}

.service-page .service-img SVG {
  width: 400px;
}

/*-------------------
 service-cards
------------------------ */

.service-cards {
  /* background-color: pink; */
  /* padding-bottom: 50px; */
  flex: 0 0 100%;
  max-width: 100%;

}


.service-page-item {
  width: calc(25% - 30px);
  margin: 0 15px 30px;
  background-color: var(--bg-black-50);
  box-shadow: var(--card-shadow);
  border-radius: 10px;


}

.service-page-item .service-page-img {
  border-radius: 4px;
  overflow: hidden;
}

.service-page-item .service-page-img img {
  width: 300px;
  height: 200px;
  padding: 15px
}

.service-page-info {
  padding: 5px 15px
}

.service-page-info h3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black-253137)
}


/* about tabs */
.about-tabs {
  /* background-color:red; */
  padding: 60px 15px 50px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.about-tabs .tab-item {
  display: inline-block;
  margin: 0 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  -webkit-transform: capitalize;
          transform: capitalize;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}

.about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}

.about-tabs .tab-item.active {
  color: var(--text-blue-700)
}

.about-tabs .tab-item:after {
  border-radius: 30px;
}


/* Responsice */

@media (max-width:991px) {

  .service-page .service-text {
    /* background-color: yellow; */
  }

  .service-page .service-text h1 {
    font-size: 35px;
  }

  .service-page .service-img {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .service-page .service-img SVG {
    width: 300px;
  }

  .service-page-item {
    width: calc(33.33% - 30px);
  }

}

@media(max-width:767px) {

  .service-page .service-text {
    /* background-color: pink; */
  }

  .service-page .service-text h1 {
    font-size: 30px;
  }

  .service-page .service-img SVG {
    width: 250px;
  }

  .service-page-item {
    width: calc(50% - 30px);
  }



}

@media (max-width:575px) {

  .service-page .service-text {
    /* background-color: red; */
  }

  .service-page .service-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .service-page .service-img {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .service-page-item {
    display: flex;
    justify-content: center;

  }

  .service-page-info {

    display: flex;
    align-items: center;
    justify-content: center;
  
  }

  .service-page-item {
    width: calc(100% - 30px);

  }


}
.careers {
    padding: 100px 0;
    /* background-color: pink; */
}

.careers-card {
    background-color: var(--text-blue-700);
    /* box-shadow: var(--card-shadow); */
    box-shadow: var(--blue-light) 0px 4px 16px, var(--blue-light) 0px 8px 32px;
    margin: auto;
    width: 50%;
    /* height:700px; */
    /* vertical-align:center */
    padding: 20px;
    border-radius: 10px;
}

.careers-card h1 {
    text-align: center;
    color: var(--white-900);
    font-family: 'poppins';
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 50px;
}


.avatar-upload {
    position: relative;
    max-width: 80px;
    margin-bottom: 40px;
    position: relative;
    border-radius: 50%;
    font-family: "FontAwesome"
}

.avatar-edit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
}

.avatar-edit input {
    display: none;
}

.avatar-edit label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid #d2d6de;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out;

}

.avatar-edit label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-edit label:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f030";
    color: var(--text-blue-700);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 34px;
    margin: auto;
}

.avatar-upload .avatar-preview img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.avatar {
    width: 60px;
    height: 60px;
    background-color: var(--blue-light);
    border-radius: 50%;
    position: relative;
    margin: 0 0 20px 0;
    line-height: 40px;

}

.avatar .i {
    width: 30px;
    height: 30px;
    /* text-align: center; */
    color: var(--white-900);
    border: 1px solid transparent;
    /* border-radius: 50%; */
    /* background-color: red; */
    position: absolute;
    top: 25%;
    left: 25%;

}

.avatar .camaeraIcon {
    width: 25px;
    height: 25px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    font-size: 12px;
    right: 0;
    bottom: 0;
    color: var(--text-blue-700);
    border-radius: 50%;
    background-color: var(--white-900);
}

.careers-card .careers-input-odd,
.careers-card .careers-input-even {
    width: 50%;

}

.careers-card .careers-input-odd {
    padding: 0 15px 0 0;
}

.careers-form textarea.form-control {
    height: 100px;
    resize: none;
    padding-top: 15px;
}

.button-wrapper {
    position: relative;
    /* width: 150px; */
    text-align: center;
    /* margin: 20% auto; */
}

.button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: var(--blue-light);
    cursor: pointer;
    color: #fff;
    padding: 13px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 30px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.careers-form .btn-1 {
    margin-bottom: 20px;
}

.message_btn {
    display: block;
    /* background-color: #fff; */
}

.message_btn p {
    display: inline;
    padding: 0 0 0 15px;
    color: var(--white-900);

}

.btn-disable {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;

}



@media (max-width:991px) {
    .careers-card {
        /* background-color: red; */
    }
}

@media(max-width:767px) {

    .careers-card {
        /* background-color: pink; */
        width: 70%;
        margin: auto;
    }
}

@media (max-width:575px) {

    .careers-card {
     
        width: 95%;
        margin: 0 10px;
    }

    .careers-card .careers-input-odd,
    .careers-card .careers-input-even {
        width: 100%;

    }

    .careers-card .careers-input-odd {
        padding: 0 0 0 0;
    }
    .position-input
    {
        margin-top:30px;
    }


}
