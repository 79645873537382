.service-top {

  padding: 120px 0 15px 0;
  position: relative;
  background-color: var(--bg-black-50);
  box-shadow: var(--outer-shadow);

}

.service-page .service-header {
  margin-bottom: 50px;
}


.service-page {
  /* background-color:yellow; */
  margin-bottom: 100px;
  padding: 15px;
}

.service-page .service-text {
  flex: 0 0 50%;
  max-width: 50%;

}

.service-page .service-text {
  /* background-color: red; */
  display: flex;

}

.service-head {}

.service-page .service-text h1 {
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--black-253137);
  line-height: 1;
  text-align: center;
  padding: 20px 0;
}

.service-page .service-text h1::before {
  content: attr(data-heading);
  display: block;
  font-size: 16px;
  margin-left: 5px;
  text-transform: lowercase;

}

.service-page .service-img {

  flex: 0 0 50%;
  max-width: 50%;
}

.service-page .service-img SVG {
  width: 400px;
}

/*-------------------
 service-cards
------------------------ */

.service-cards {
  /* background-color: pink; */
  /* padding-bottom: 50px; */
  flex: 0 0 100%;
  max-width: 100%;

}


.service-page-item {
  width: calc(25% - 30px);
  margin: 0 15px 30px;
  background-color: var(--bg-black-50);
  box-shadow: var(--card-shadow);
  border-radius: 10px;


}

.service-page-item .service-page-img {
  border-radius: 4px;
  overflow: hidden;
}

.service-page-item .service-page-img img {
  width: 300px;
  height: 200px;
  padding: 15px
}

.service-page-info {
  padding: 5px 15px
}

.service-page-info h3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black-253137)
}


/* about tabs */
.about-tabs {
  /* background-color:red; */
  padding: 60px 15px 50px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.about-tabs .tab-item {
  display: inline-block;
  margin: 0 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  transform: capitalize;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}

.about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}

.about-tabs .tab-item.active {
  color: var(--text-blue-700)
}

.about-tabs .tab-item:after {
  border-radius: 30px;
}


/* Responsice */

@media (max-width:991px) {

  .service-page .service-text {
    /* background-color: yellow; */
  }

  .service-page .service-text h1 {
    font-size: 35px;
  }

  .service-page .service-img {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .service-page .service-img SVG {
    width: 300px;
  }

  .service-page-item {
    width: calc(33.33% - 30px);
  }

}

@media(max-width:767px) {

  .service-page .service-text {
    /* background-color: pink; */
  }

  .service-page .service-text h1 {
    font-size: 30px;
  }

  .service-page .service-img SVG {
    width: 250px;
  }

  .service-page-item {
    width: calc(50% - 30px);
  }



}

@media (max-width:575px) {

  .service-page .service-text {
    /* background-color: red; */
  }

  .service-page .service-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .service-page .service-img {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .service-page-item {
    display: flex;
    justify-content: center;

  }

  .service-page-info {

    display: flex;
    align-items: center;
    justify-content: center;
  
  }

  .service-page-item {
    width: calc(100% - 30px);

  }


}