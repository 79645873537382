.careers {
    padding: 100px 0;
    /* background-color: pink; */
}

.careers-card {
    background-color: var(--text-blue-700);
    /* box-shadow: var(--card-shadow); */
    box-shadow: var(--blue-light) 0px 4px 16px, var(--blue-light) 0px 8px 32px;
    margin: auto;
    width: 50%;
    /* height:700px; */
    /* vertical-align:center */
    padding: 20px;
    border-radius: 10px;
}

.careers-card h1 {
    text-align: center;
    color: var(--white-900);
    font-family: 'poppins';
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 50px;
}


.avatar-upload {
    position: relative;
    max-width: 80px;
    margin-bottom: 40px;
    position: relative;
    border-radius: 50%;
    font-family: "FontAwesome"
}

.avatar-edit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
}

.avatar-edit input {
    display: none;
}

.avatar-edit label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid #d2d6de;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out;

}

.avatar-edit label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-edit label:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f030";
    color: var(--text-blue-700);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 34px;
    margin: auto;
}

.avatar-upload .avatar-preview img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.avatar {
    width: 60px;
    height: 60px;
    background-color: var(--blue-light);
    border-radius: 50%;
    position: relative;
    margin: 0 0 20px 0;
    line-height: 40px;

}

.avatar .i {
    width: 30px;
    height: 30px;
    /* text-align: center; */
    color: var(--white-900);
    border: 1px solid transparent;
    /* border-radius: 50%; */
    /* background-color: red; */
    position: absolute;
    top: 25%;
    left: 25%;

}

.avatar .camaeraIcon {
    width: 25px;
    height: 25px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    font-size: 12px;
    right: 0;
    bottom: 0;
    color: var(--text-blue-700);
    border-radius: 50%;
    background-color: var(--white-900);
}

.careers-card .careers-input-odd,
.careers-card .careers-input-even {
    width: 50%;

}

.careers-card .careers-input-odd {
    padding: 0 15px 0 0;
}

.careers-form textarea.form-control {
    height: 100px;
    resize: none;
    padding-top: 15px;
}

.button-wrapper {
    position: relative;
    /* width: 150px; */
    text-align: center;
    /* margin: 20% auto; */
}

.button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: var(--blue-light);
    cursor: pointer;
    color: #fff;
    padding: 13px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 30px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.careers-form .btn-1 {
    margin-bottom: 20px;
}

.message_btn {
    display: block;
    /* background-color: #fff; */
}

.message_btn p {
    display: inline;
    padding: 0 0 0 15px;
    color: var(--white-900);

}

.btn-disable {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;

}



@media (max-width:991px) {
    .careers-card {
        /* background-color: red; */
    }
}

@media(max-width:767px) {

    .careers-card {
        /* background-color: pink; */
        width: 70%;
        margin: auto;
    }
}

@media (max-width:575px) {

    .careers-card {
     
        width: 95%;
        margin: 0 10px;
    }

    .careers-card .careers-input-odd,
    .careers-card .careers-input-even {
        width: 100%;

    }

    .careers-card .careers-input-odd {
        padding: 0 0 0 0;
    }
    .position-input
    {
        margin-top:30px;
    }


}