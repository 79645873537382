/* Effect */

.about-page {
    padding: 160px 0 0 0;
    position: relative;
    margin-bottom: 100px;
}


/*----------------------
Side Menu
---------------------- */

.about-page .about-side-menu {

    /* background-color: green; */
    flex: 0 0 20%;
    max-width: 20%;
    /* background-color: white;
    box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 10px; */
}

.about-page .about-side-menu .more-in-about {

    border-radius: 5px;

}


.about-page .about-side-menu .more-in-about span {
    color: var(--black-253137);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding: 5px;
    display: block;
    margin: 10px 0 0 15px;
    text-align: right;
    opacity: 0.5;
}

.about-page .about-side-menu ul {
    /* list-style: none; */
    margin: 15px 0 0 0;
}

.about-page .about-side-menu ul li {
    display: block;
    text-align: right;
    cursor: pointer;


}


.about-page .about-side-menu ul li div {

    font-size: 14px;
    color: var(--black-253137);
    font-weight: 400;
    padding: 10px 0px;
    opacity: 0.5;
}

.about-page .about-side-menu ul li div:hover {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-blue-700);
    transition: all 0.5s ease;
    opacity: 1;

}

.about-page .about-side-menu ul li div.active-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-blue-700);
    transition: all 0.5s ease;
    opacity: 1;
}

.about-page .about-side-menu ul li div span {
    padding: 0 15px;
    cursor: pointer;
}


/*------------------------
Drawer right
------------------------ */
.side-menu-drawer {

    margin-right: 20px;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 80px;
    display: none;
}


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20;
    top: 6.5%;
    right: 0;
    background-color: var(--blue-light);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/*  */


.sidenav .more-in-about {

    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    padding-right: 30px;

}


.sidenav .about-side-menu .more-in-about span {
    color: var(--black-253137);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding: 5px;
    display: block;
    margin: 10px 0 0 15px;
    text-align: right;
    opacity: 0.5;
}

.sidenav ul {
    /* list-style: none; */
    margin: 15px 0 0 0;

}

.sidenav ul li {
    display: block;
    text-align: right;
    cursor: pointer;
    padding-right: 30px;


}


.sidenav ul li div {

    font-size: 14px;
    color: var(--white-800);
    font-weight: 400;
    padding: 10px 0px;
    opacity: 0.5;
}

.sidenav ul li div:hover {
    font-size: 15px;
    font-weight: 500;
    color: var(--white-800);
    transition: all 0.5s ease;
    opacity: 1;

}

.sidenav ul li div.active-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--white-900);
    transition: all 0.5s ease;
    opacity: 1;
}

.sidenav ul li div span {
    padding: 0 15px;
    cursor: pointer;
}


@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

/* ----------------------
Certificate
------------------------- */
.about-page .certification-info {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;

}

.about-page .certification-info .certificate-image {

    flex: 0 0 40%;
    max-width: 40%;
    position: relative;

}

.certificate-inner-image .img-rectange {
    /* background-color: var(--text-blue-700); */
    border:2px solid var(--text-blue-700);
    width: 100%;
    opacity: 1;
     position: relative;
}

.certificate-image .certificate-inner-image {
    padding: 15px;
    position: relative;
    
}

.certificate-image .certificate-inner-image::before,
.certificate-image .certificate-inner-image::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border: 2px solid var(--text-blue-700);
  transition: all .25s ease-out;
}

.certificate-image .certificate-inner-image::before {
  background-color: var(--text-blue-700);
  box-shadow:var(--card-shadow);
  top: -10px;
  left: -10px;
}
.certificate-image .certificate-inner-image::after {
  bottom: -10px;
  right: -10px;
}
.certificate-image .certificate-inner-image:hover::after  {

  
    bottom: 10px;
    right: 10px;
  
}
.certificate-image .certificate-inner-image:hover::before {
  top: 10px;
  left: 10px;
}
.certificate-image .certificate-inner-image:hover::after {
  bottom: 10px;
  right: 10px;
}

.certificate-inner-image img {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 4;
    transition:all 0.5s ease;
}
.certificate-inner-image img:hover
{
    top: 0px;
    left: 0px;
}


.about-page .certification-info .certificate-text {

    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 15px;
}

.certificate-heading h1 {
    text-transform: uppercase;
    color: var(--black-253137);
    padding: 0 0 15px 0;
}


.certificate-heading p {
    /* background-color: red; */
    line-height: 1.5;
    font-size: 16px;
}


.certificate-heading h1 span {
    display: block;
    margin-left: 50px;
}



/* ---------------------------
Quality Policy 
----------------------------*/

.about-page .about-quality-policy {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;
    /* background-color: red; */
}

.about-page .about-quality-policy .quality-policy-text {
    position: relative;
}

.about-page .about-quality-policy .inner-text {
    color: var(--black-253137);

}

.about-page .about-quality-policy .inner-text h1 {
    text-transform: uppercase;
    font-size: 45px;
    margin: 0 0 15px 0;
}

.about-page .about-quality-policy .inner-text p {
    text-align: justify;
    line-height: 1.5;
    font-size: 16px;
}

.firstcharacter {
    color: #5f79ff;
    float: left;
    margin: 0 8px 0 4px;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
}

.about-page .about-quality-policy .quality-policy-text .inner-text .inner-img {
    width: 50%;
    float: right;
    /* background-color: pink; */

}

.about-page .about-quality-policy .quality-policy-text .inner-text .inner-img img {
    padding: 0 10px;
}

.about-page .quality-policy-list {
    /* background-color: pink; */
}

.about-page .quality-policy-list ul {
    margin: 10px 0;
}

.about-page .quality-policy-list ul li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1em;
    padding: 5px 0;
    color: var(--black-253137)
}

/*------------------------
Introduction Letter
-------------------------  */

.about-page .intro-letter {

    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 15%;
    transition: all 0.5s ease;
    /* background-color: red; */
}

.about-page .intro-letter .inner-text {
    color: var(--black-253137);
}

.about-page .intro-letter .inner-text h1 {
    text-transform: uppercase;
    font-size: 40px;
    margin: 0 0 15px 0;
}

.about-page .intro-letter .inner-text img {
    float: right;
    width: 40%;
}

.about-page .intro-letter .inner-text p {
    text-align: justify;
}

.about-page .intro-letter .inner-text p p span {
    margin: 10px 20px 20px 0;
}

.about-page .intro-letter .inner-text p p {
    margin: 10px 20px 20px 0;
}


/*----------------------------
Responsive
---------------------------- */
@media (max-width:991px) {
    .side-menu-drawer {
        display: block;
    }
    
    .about-page
    {
        padding: 50px 0 0 0;
    }
    .about-page .about-side-menu {
        /* background-color: red; */
        display: none;
    }

    .about-page .certification-info {

        flex: 0 0 80%;
        max-width: 80%;
        margin-left: 15%;
        /* margin:0 0 0 50px; */
    }


}

@media(max-width:767px) {

    .about-page .about-quality-policy {

        flex: 0 0 80%;
        max-width: 80%;
        /* margin-left: 15%; */
        /* background-color: red; */
    }

    .about-page .intro-letter {

        flex: 0 0 80%;
        max-width: 80%;
        transition: all 0.5s ease;
        /* background-color: red; */
    }

}

@media (max-width:575px) {

    .about-page {
        padding: 50px 0 0 0;
    }
    
    .about-page .certification-info {
        /* background-color: red; */
    }

    .about-page .certification-info .certificate-image {
        flex: 0 0 80%;
        max-width: 80%;

    }

    .certificate-inner-image .img-rectange {
        width: 80%;
        margin-bottom: 50px;

    }

    .certificate-inner-image img {
        max-width: 100%;
        left: 70px;
        top: -50px;
        z-index: 10;
        transition: all 0.9s ease;
        animation: topBounce;
    }

    .about-page .certification-info .certificate-text {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
    }

    .certificate-heading {
        /* background-color: pink; */
        line-height: 1.2;
    }

    .certificate-heading h1 {
        text-align: center;
        margin-top: 50px;
    }

    .certificate-heading h1 span {
        /* display: block; */
        margin-left: 0px;
    }

    .about-page .about-quality-policy {

        flex: 0 0 80%;
        max-width: 80%;
        /* margin-left: 15%; */
        /* background-color: red; */
    }

    .about-page .intro-letter {

        flex: 0 0 80%;
        max-width: 80%;
        transition: all 0.5s ease;
        /* background-color: red; */
    }
}