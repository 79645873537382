:root {
    --bg-black-900: #000000;
    --bg-black-100: #dddddd;
    --bg-black-50: #eff0f4;
    --text-black-900: #000000;
    --text-black-700: #555555;
    --text-black-600: #666666;
    --text-black-300: #bbbbbb;
    --text-blue-700:#001D5D;
    --outer-shadow: 3px 3px 3px #d0d0d0, -3px -3px 3px #f8f8f8;
    --outer-shadow-0: 0px 0px 0px #d0d0d0, 0px 0px 0px #f8f8f8;
    --inner-shadow: inset 3px 3px 3px #d0d0d0, inset -3px -3px 3px #f8f8f8;
    --skin-color:#fb839e;
    --white-900:#ffffff;
    --white-800:rgba(255,255,255,0.8);
    --shadow: 0 0 15px rgba(0,0,0.2);
    --color-gray:#5E5C5F;
    --black-121212:#121212;
    --black-181818:#181818;
    --black-404040:#404040;
    --black-282828:#282828;
    --black-B3B3B3:#B3B3B3;
    --black-253137:#253137;
    --blue-light:#2D4579;
    --card-shadow:rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;


      scroll-behavior:smooth;
  }

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */

 .tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}